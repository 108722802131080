/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { oembed } from "@loomhq/loom-embed";
import { createInstance } from "@loomhq/record-sdk";
import { isSupported } from "@loomhq/record-sdk/is-supported";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function SignupStep8() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // greaterfiveminutes
  const [greaterfiveminutes, setgreaterfiveminutes] = useState(false);
  const [formData, setFormData] = useState({
    productImage: "",
    productDescription: "",
    productVideo: "",
    productLogo: "",
    website: "",
    twitter: "",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
  });
  const [errors, setErrors] = useState({});
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [toottip, SetTooltip] = useState(false);
  const handleChange = e => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024;
      if (name === "productLogo") {
        const file = files[0];
        const maxSize = 5 * 1024 * 1024;

        if (file.size > maxSize) {
          toast.warn("File size should not exceed 5MB", {
            position: "top-center",
          });
          return;
        }
        setFormData({
          ...formData,
          [name]: file,
        });
      } else if (file.size > maxSize) {
        toast.warn("File size should not exceed 5MB", {
          position: "top-center",
        });
      } else {
        setFormData({
          ...formData,
          [name]: file,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const getFileName = file => {
    return file && typeof file === "object" ? file.name : file;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.productImage) {
      newErrors.productImage = "Product Image is required.";
    }
    if (!formData.productDescription) {
      newErrors.productDescription = "Product Description is required.";
    } else if (formData.productDescription.length > 1500) {
      newErrors.productDescription =
        "Product Description cannot exceed 1500 characters.";
    }
    // if (
    //   !formData.productVideo ||
    //   formData.productVideo === null ||
    //   formData.productVideo === "null"
    // ) {
    //   newErrors.productVideo = "Product Video is required.";
    // }
    if(greaterfiveminutes){
      newErrors.productVideo = "Video duration should not exceed 5 minutes.";
    }
    if (!formData.productLogo) {
      newErrors.productLogo = "Product Logo is required.";
    }
    if (formData.facebook) {
      if (!/^https?:\/\/.*/i.test(formData.facebook)) {
        newErrors.facebook =
          "Facebook URL must start with https:// or http:// ";
      }
    }
    if (formData.twitter) {
      if (!/^https?:\/\/.*/i.test(formData.twitter)) {
        newErrors.twitter = "Twitter URL must start with https:// or http:// ";
      }
    }
    if (formData.linkedin) {
      if (!/^https?:\/\/.*/i.test(formData.linkedin)) {
        newErrors.linkedin =
          "Linkedin URL must start with https:// or http:// ";
      }
    }
    if (formData.youtube) {
      if (!/^https?:\/\/.*/i.test(formData.youtube)) {
        newErrors.youtube = "Youtube URL must start with https:// or http:// ";
      }
    }
    if (formData.website) {
      if (!/^https?:\/\/.*/i.test(formData.website)) {
        newErrors.website = "Website URL must start with https:// or http:// ";
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("product_image", formData.productImage);
      formdata.append(
        "product_details",
        formData.productDescription != "" ? formData.productDescription : ""
      );
      formdata.append("video", formData.productVideo);
      formdata.append(
        "twitter",
        formData.twitter != null ? formData.twitter : ""
      );
      formdata.append(
        "facebook",
        formData.facebook != null ? formData.facebook : ""
      );
      formdata.append(
        "linkedin",
        formData.linkedin != null ? formData.linkedin : ""
      );
      formdata.append(
        "youtube",
        formData.youtube != null ? formData.youtube : ""
      );
      formdata.append("logo", formData.productLogo);
      formdata.append("website", formData.website);
      formdata.append(
        "instagram",
        formData.instagram != null ? formData.instagram : ""
      );

      post(`${apiurl}startups/aboutBusiness/${Userid}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/about-business2");
              navigate("/about-business2");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setFormData({ ...formData, productVideo: url });
    setErrors({
      ...errors,
      productVideo: "",
    });
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          const startupData = response?.data?.startups;
          setFormData({
            productImage: startupData?.product_image,
            productDescription: startupData?.product_details,
            productVideo: startupData?.video,
            productLogo: startupData?.logo,
            website:
              startupData?.website != "undefined" ? startupData?.website : "",
            twitter:
              startupData?.twitter != "undefined" ? startupData?.twitter : "",
            facebook:
              startupData?.facebook != "undefined" ? startupData?.facebook : "",
            linkedin:
              startupData?.linkedin != "undefined" ? startupData?.linkedin : "",
            youtube:
              startupData?.youtube != "undefined" ? startupData?.youtube : "",
            instagram:
              startupData?.instagram != "undefined"
                ? startupData?.instagram
                : "",
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const PUBLIC_APP_ID = process.env.REACT_APP_PUBLIC_APP_ID;
  const BUTTON_ID = "loom-record-sdk-button";
  const [loom, setloom] = useState(0);
  const handleLoomClick = async event => {
    event.preventDefault();
    setloom(loom + 1);
  };

  useEffect(() => {
    async function setupLoom() {
      const { supported } = await isSupported();
      if (!supported) {
        return;
      }

      const button = document.getElementById(BUTTON_ID);

      if (!button) {
        return;
      }

      try {
        const loomClient = await createInstance({
          publicAppId: PUBLIC_APP_ID,
          mode: "standard",
        });

        const sdkButton = loomClient.configureButton({ element: button });

        sdkButton.on("insert-click", async video => {
          const { html } = await oembed(video.sharedUrl, { width: 400 });
          // Update form data with the video HTML embed code
          setFormData(prevFormData => ({
            ...prevFormData,
            productVideo: html,
          }));
        });
      } catch (sdkErr) {
        console.error("Loom SDK configuration failed", sdkErr);
      }
    }

    // if(loom != 0) {
    // }
    setupLoom();
  }, [loom]);

  const openTooltip = () => {
    SetTooltip(true);
  };

  const closeTooltip = () => {
    SetTooltip(false);
  };

  const divRef = useRef(null);

  useEffect(() => {
    const div = divRef.current;
    // console.log(div)
    if (div) {
      const iframes = div.querySelectorAll("iframe");
      iframes.forEach(iframe => {
        iframe.style.width = "100%";
        iframe.style.height = "300px";
      });
    }
  }, [formData.productVideo]);

  const handleVideoChange = event => {
    const file = event.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      const videoElement = document.createElement("video");

      videoElement.src = videoUrl;
      videoElement.addEventListener("loadedmetadata", () => {
        const duration = videoElement.duration;
        console.log(duration)
        if (duration > 300) {
       
          setErrors({
            productVideo: "Video duration should not exceed 5 minutes.",
          });
          setgreaterfiveminutes(true);
        } else {
          setErrors({
            productVideo: "",
          });
          setFormData(prevFormData => ({
            ...prevFormData,
            productVideo: file ,
          }));
          setgreaterfiveminutes(false)
         
        }
      });
    }
  };

  // const renderVideo = () => {
  //   const { productVideo } = formData;

  //   // If the productVideo is an iframe, extract the src
  //   if (productVideo && productVideo.includes("<iframe")) {
  //     const srcMatch = productVideo.match(/src="([^"]+)"/);
  //     if (srcMatch && srcMatch[1]) {
  //       return (
  //         <iframe
  //           src={srcMatch[1]}
  //           frameBorder="0"
  //           width="100%"
  //           height="100%"
  //           allowFullScreen
  //           title="Loom Video"
  //         ></iframe>
  //       );
  //     }
  //   } else if (productVideo) {
  //     return (
  //       <video
  //         className="VideoInput_video"
  //         width="100%"
  //         height="100%"
  //         controls
  //         src={productVideo}
  //       />
  //     );
  //   }

  //   return null;
  // };
  const [videsrc,setVideosrc]=useState()
  function extractSrcFromIframe(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const iframe = doc.querySelector("iframe");
    return iframe ? iframe.src : html;
  }

  
useEffect(()=>{
  if(formData.productVideo!=''||formData.productVideo!=null){
    const iframeHtml=formData.productVideo
    const videoSrc = extractSrcFromIframe(iframeHtml);
    setVideosrc(videoSrc)
  }

},[formData.productVideo])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || About business 1</title>
      </Helmet>
      <div className="VideoInput"></div>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Identity and Business Verification
                  </h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4 className="blurred-text">Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Unit Economics and Business Growth
                  </h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Legal Compliance and Declarations
                  </h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4>About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">About Business</h5>
                <div className="Eligibility-section">
                  <div className="Eligibility-icon">
                    <img src="src/images/vectors.png" alt="" />
                  </div>
                  <div className="Eligibility-text">
                    <p>
                      Your pitch deck, video, and product description are not
                      publicly accessible; they are available exclusively to
                      interested investors.
                    </p>
                  </div>
                </div>
                <form>
                  <h5>Product Image</h5>
                  <div className="form-group full">
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>File Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="uplodimage"
                            name="productImage"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg,"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="uplodimage"
                          >
                            {getFileName(formData.productImage)
                              ? getFileName(formData.productImage).length > 20
                                ? `${getFileName(formData.productImage).slice(
                                    0,
                                    20
                                  )}...`
                                : getFileName(formData.productImage)
                              : "Upload Product Image"}

                            <span className="required-sign">*</span>
                          </label>
                        </li>
                        {errors.productImage && (
                          <p className="error-msg">{errors.productImage}</p>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Description of Product
                      <span className="required-sign">*</span>
                    </label>
                    <textarea
                      name="productDescription"
                      className="form-control"
                      placeholder="Enter your text here (maximum 1500 characters)"
                      value={formData.productDescription}
                      onChange={handleChange}
                    ></textarea>
                    {errors.productDescription && (
                      <p className="error-msg">{errors.productDescription}</p>
                    )}
                    <p>
                      *Please provide a description of your product only. Please
                      refrain from including your website URL or contact details
                      in this section.
                    </p>
                    <div className="character-counter">
                      {formData.productDescription ? (
                        <>
                          {1500 - formData.productDescription.length} characters
                          remaining
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="bottomborder"></div>
                  <h5>Video</h5>
                  <div className="form-group">
                    <label htmlFor="">
                      Requirements{" "}
                      <small>
                        (To build a human connection with investors as if
                        pitching live. {window.innerWidth > 1024 ? " Please use Chrome browser to record this video.":""})<span className="required-sign">*</span>
                      </small>
                    </label>
                    <div className="video-box">
                      <div className="s-logo">
                        <img src="src/images/s-logo.svg" alt="" />
                      </div>
                      {window.innerWidth > 1024 ? (
                        <>
                          <input
                            className="VideoInput_input"
                            type="file"
                            id="videoUpload"
                            onChange={handleFileChange}
                            // onSubmit={handleChange}
                            accept=".mp4"
                          />

                          {formData.productVideo &&
                            formData.productVideo != "null" && (
                           
                              // <div
                           
                              //   ref={divRef}
                              //   dangerouslySetInnerHTML={{
                              //     __html: formData.productVideo,
                              //   }}
                              // ></div>
                              <iframe src={videsrc} allow="fullscreen" title="video"/>
                              
                            )}
                          <label
                            className="custom-file-label"
                            htmlFor="videoUpload"
                          >
                      

                            <div
                              className={
                                formData.productVideo &&
                                formData.productVideo != "null"
                                  ? "play-icon aftervideoupload"
                                  : "play-icon"
                              }
                              onClick={handleLoomClick}
                            >
                              <button id={BUTTON_ID}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 1024 1024"
                                  width="50"
                                  height="60"
                                  fill="#43b8f5"
                                >
                                  <path d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z" />
                                </svg>
                              </button>
                            </div>

                            {/* )} */}
                          </label>
                        </>
                      ) : (
                        <>
                        
                     
                          <h5>Product Video File</h5>
                  <div className="form-group full">
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>Upload Video File</h6>
                          <input
                            className="VideoInput_input"
                            type="file"
                            id="uplodvideo"
                            onChange={handleVideoChange}
                            accept=".mp4,.iframe"
                            
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="uplodvideo"
                          >
                            {getFileName(formData.productVideo)
                              ? getFileName(formData.productVideo).length > 20
                                ? `${getFileName(formData.productVideo).slice(
                                    0,
                                    20
                                  )}...`
                                : getFileName(formData.productVideo)
                              : "Upload Product Video"}

                            <span className="required-sign">*</span>
                          </label>
                        </li>
                        {/* {errors.productVideo && (
                          <p className="error-msg">{errors.productVideo}</p>
                        )} */}
                      </ul>
                    </div>
                  </div>
                        


                        </>
                      )}

                      <div className="progress-bar flex align-center space-between">
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                        <div className="progress-bar-line"></div>
                      </div>
                    </div>
                    {errors.productVideo && (
                      <p className="error-msg">{errors.productVideo}</p>
                    )}
                    <p>{window.innerWidth>768?'*Recorded using Loom, preferably under 5 minutes.':'Video duration must be under 5 minutes.'}</p>
                  </div>
                  <div className="video-tips">
                    <p>
                      {" "}
                      <img
                        src="src/images/InfoCircleFill.png"
                        alt=""
                        onMouseEnter={() => openTooltip()}
                        onMouseLeave={() => closeTooltip()}
                      />{" "}
                      Tips on how to record a video
                    </p>
                    {toottip && (
                      <div className="tooltip-container tooltipvideotips">
                        <div className="tooltiptext">
                          <div className="tooltip-arrow tooltipvideoarrow"></div>
                          Explain yourself and your business under 5 minutes.{" "}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-group bordertop">
                    <h5 className="mb-12">Links (If applicable)</h5>
                    <input
                      type="text"
                      name="website"
                      placeholder="https://websites.com"
                      className="form-control"
                      value={formData.website}
                      onChange={handleChange}
                    />
                    {errors.website && (
                      <p className="error-msg">{errors.website}</p>
                    )}
                    <input
                      type="text"
                      placeholder="https://twitter.com"
                      className="form-control"
                      name="twitter"
                      value={formData.twitter}
                      onChange={handleChange}
                    />
                    {errors.twitter && (
                      <p className="error-msg">{errors.twitter}</p>
                    )}
                    <input
                      type="text"
                      placeholder="https://facebook.com"
                      className="form-control"
                      name="facebook"
                      value={formData.facebook}
                      onChange={handleChange}
                    />
                    {errors.facebook && (
                      <p className="error-msg">{errors.facebook}</p>
                    )}
                    <input
                      type="text"
                      placeholder="https://instagram.com"
                      className="form-control"
                      name="instagram"
                      value={formData.instagram}
                      onChange={handleChange}
                    />
                    {errors.instagram && (
                      <p className="error-msg">{errors.instagram}</p>
                    )}
                    <input
                      type="text"
                      placeholder="https://linkedIn.com"
                      className="form-control"
                      name="linkedin"
                      value={formData.linkedin}
                      onChange={handleChange}
                    />
                    {errors.linkedin && (
                      <p className="error-msg">{errors.linkedin}</p>
                    )}
                    <input
                      type="text"
                      placeholder="https://youtube.com"
                      className="form-control"
                      name="youtube"
                      value={formData.youtube}
                      onChange={handleChange}
                    />
                    {errors.youtube && (
                      <p className="error-msg">{errors.youtube}</p>
                    )}
                  </div>
                  <div className="form-group full bordertop">
                    <h5 className="mb-12">Logo</h5>
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>File Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="productLogo"
                            name="productLogo"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="productLogo"
                          >
                            {getFileName(formData.productLogo)
                              ? getFileName(formData.productLogo).length > 20
                                ? `${getFileName(formData.productLogo).slice(
                                    0,
                                    20
                                  )}...`
                                : getFileName(formData.productLogo)
                              : "Upload the company logo"}

                            <span className="required-sign">*</span>
                          </label>
                        </li>
                      </ul>
                      {errors.productLogo && (
                        <p className="error-msg">{errors.productLogo}</p>
                      )}
                    </div>
                  </div>
                </form>
                <div className="btn-box flex space-between">
                  <Link
                    to="/legal-compliance-declarations"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep8;
