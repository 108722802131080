/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { PDFDocument } from "pdf-lib";

import { apiurl } from "../Url";
import CountryList from "../../common/Country";
import { post, get } from "../../services/services";
import  { setItem,removeItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";
import Footer3 from "../../common/Footer3";

function SignupStep3() {
  const navigate = useNavigate();
  const countries = CountryList();
  const [loading, setLoading] = useState(false);
  const [invalidpost, Setisinvalidpost] = useState(false);
  const [formData, setFormData] = useState({
    businessName: "",
    founderName: "",
    dob: "",
    email: "",
    businessRegistrationNumber: "",
    country: "",
    city: "",
    state: "",
    postalCode: "",
    lifetimeRevenue: "",
    bankStatements: "",
    lat: "",
    long: "",
    phoneNumber: "",
    administrativeAreaLevel1:''
  });
  const [errors, setErrors] = useState({});
  const [errorsruppee, setErrorsrupee] = useState(false);
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [checkfirsttimepost,setcheckfirsttimepost]=useState(true);
  const apiKey = process.env.REACT_APP_LOCATION_API_KEY;
  const getLonglat = async () => {
    if(formData.postalCode!=""){
      setLoading(true);
    }
  
    try {
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: formData.postalCode,
            key: apiKey,
          },
        }
      );

      if (response.data.status === "OK") {
      
        const administrativeAreaLevel1 = response.data.results[0].address_components.find(component => 
          component.types.includes("administrative_area_level_1")
      )?.long_name;
      
   
     
        setFormData(prevData => ({
          ...prevData,
          lat: response.data.results[0].geometry.location.lat,
          long: response.data.results[0].geometry.location.lng,
          administrativeAreaLevel1:administrativeAreaLevel1,
        }));
     
        setLoading(false);
        Setisinvalidpost(false);
      } else {
        setErrors({
          ...errors,
          postalCode: "Please enter valid Postal code",
        });
        setLoading(false);
        Setisinvalidpost(true);
      }
    } catch (err) {
      setLoading(false)
    }
  };

  const handleChange = async e => {
    const { name, value, files } = e.target;
    const phoneNumberPattern = /^[+\d\s\-\(\)\.\/]*$/;
    if (name === "phoneNumber") {
      if (!phoneNumberPattern.test(value)) {
        setErrors({ ...errors, [name]: "Alphabets not allowed" });
        return;
      }
    }
    if (name === "dob") {
      const today = new Date();
      const dob = new Date(value);
      const age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      const dayDifference = today.getDate() - dob.getDate();

      if (
        age < 18 ||
        (age === 18 &&
          (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
      ) {
        setErrors({
          ...errors,
          dob: "You must be at least 18 years old",
        });
        return;
      } else {
        setErrors({
          ...errors,
          dob: "",
        });
      }
    }

    if (name === "postalCode") {
      const regex = /^[+\d\s\-\(\)\.\/]*$/;

      if (!regex.test(value)) {
        setErrors({
          ...errors,
          postalCode: "Only digits allowed",
        });
        Setisinvalidpost(true);
        return;
      }else{
        Setisinvalidpost(false);
      }
      if (value.length > 12) {
        setErrors({
          ...errors,
          postalCode: "Maximum allowed 10 digit",
        });
        return;
      }
    }

    if (files && files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024;

      if (file.size > maxSize) {
        toast.warn("File size should not exceed 5MB", {
          position: "top-center",
        });
      } else {
        setFormData({
          ...formData,
          [name]: file,
        });
      }

      if (file.type === "application/pdf") {
        try {
          const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
          const numPages = pdfDoc.getPageCount();
    
          if (numPages > 10) {
            toast.warn("File should not excedd 10 pages", {
              position: "top-center",
            });
            return;
          } else {
            // setErrors({ bankStatements: '' });
          }
        } catch (error) {
          console.error("Error loading PDF document:", error);
        }
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    } else if (formData.phoneNumber.length < 6) {
      newErrors.phoneNumber = "Phone Number is not Less then 6 Number.";
    }
    if (!formData.businessName) {
      newErrors.businessName = "Business Name is required.";
    }
    if (!formData.founderName) {
      newErrors.founderName = "Founder's Name is required.";
    }
    if (!formData.dob) {
      newErrors.dob = "Date of Birth is required.";
    }
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.country) {
      newErrors.country = "Country is required.";
    }
    if (!formData.city) {
      newErrors.city = "City is required.";
    }
    if (!formData.state) {
      newErrors.state = "State is required.";
    }
    if (!formData.postalCode) {
      newErrors.postalCode = "Postal Code is required.";
    } else if (formData.postalCode.length < 4) {
      newErrors.postalCode = "Minimum 4 digit required for postalcode";
    } else if (formData.postalCode.length > 10) {
      newErrors.postalCode = "Maximum 10 digit allowed for postalcode";
    } else if (invalidpost && formData.postalCode) {
      newErrors.postalCode = "Invalid Post code";
    }else if (formData?.state?.toLowerCase() !== formData?.administrativeAreaLevel1?.toLowerCase()) {
      newErrors.postalCode = "Please enter a valid postal code according to your State";
  }
    if (!formData.bankStatements) {
      newErrors.bankStatements = "Bank Statements is required";
    }
    if (!formData.lifetimeRevenue) {
      newErrors.lifetimeRevenue = "Lifetime Revenue is required";
    }
    if (formData.lifetimeRevenue == "No") {
      newErrors.lifetimeRevenue = "Minimum 15 lakh amount is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("business_name", formData.businessName);
      formdata.append("founder_name", formData.founderName);
      formdata.append("date_of_birth", formData.dob);
      formdata.append("business_email", formData.email);
      formdata.append("phone_number", formData.phoneNumber);

      formdata.append(
        "business_registration_number",
        formData.businessRegistrationNumber != null
          ? formData.businessRegistrationNumber
          : ""
      );
      formdata.append("city", formData.city);
      formdata.append("state", formData.state);
      formdata.append("pin_code", formData.postalCode);
      formdata.append("country", formData.country);
      formdata.append("bank_statement", formData.bankStatements);
      formdata.append("genratedRs", formData.lifetimeRevenue);
      formdata.append("lat", formData.lat);
      formdata.append("long", formData.long);
      post(`${apiurl}startups/${Userid}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/identity-business-verfication2");
              navigate("/identity-business-verfication2");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const getFileName = file => {
    return file && typeof file === "object" ? file.name : file;
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          const startupData = response?.data?.startups;
          const date = new Date(startupData.date_of_birth);
          const formattedDate = date.toISOString().split("T")[0];
          setFormData({
            businessName: startupData.business_name,
            founderName: startupData.founder_name,
            dob: formattedDate,
            email: startupData.business_email,
            phoneNumber: startupData.phone_number,
            businessRegistrationNumber:
              startupData.business_registration_number,
            city: startupData.city,
            state: startupData.state,
            postalCode: startupData.pin_code,
            country: startupData.country,
            bankStatements: startupData.bank_statement,
            lifetimeRevenue: startupData.genratedRs,
            lat: startupData.lat,
            long: startupData.long,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${day}-${month}`;
  };
  useEffect(() => {
    // if (formData.lifetimeRevenue === "No") {
    //   setErrorsrupee(true);
    // } else {
    //   setErrorsrupee(false);
    // }
  }, [formData]);

  const closesorrymodal = () => {
    setErrorsrupee(!errorsruppee);
  };

  const gotostartupsignup=(e)=>{
    e.preventDefault()
    removeItem("token");
    removeItem("userType");
    removeItem("UserId");
    navigate('/startup-signup')
  }

  useEffect(()=>{
    console.log(formData.postalCode!="null",formData.postalCode)
    if(checkfirsttimepost&&formData.postalCode!=''&&formData.postalCode!="null"){
      console.log('useeffect running')
      getLonglat()
      setcheckfirsttimepost(false)
    }

  },[formData.postalCode])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Identity Business Verification 1</title>
      </Helmet>
      <section className="signUp-Sec">
        {errorsruppee && <div className="modal-backdrop"></div>}
        {errorsruppee && (
          <div className="custom-modal">
            <div className="popup-wrapper pop-open sorrymodal">
              <div className="pop-cross-btn sorrymodalcross">
                <img
                  src="src/images/cross-icon.svg"
                  alt=""
                  onClick={closesorrymodal}
                />
              </div>
              <div className="sorry-popup">
                <h1>Sorry!</h1>
                <p>
                  Currently, we require a minimum of INR 15 lakhs in lifetime revenue to
                  post your business for funding.
                </p>

                <form action="" className="sorryform">
                  <div className="form-wrapper-box flex">
                    <div className="form-group btngroup full-width flex">
                      <button
                        className="cta-btn sorrygotbtn"
                        onClick={closesorrymodal}
                      >
                        Got it
                      </button>
                      {/* <button
                        className="cta-btn sorrycancelbtn"
                        onClick={closesorrymodal}
                      >
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex active">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4>Identity and Business Verification</h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4>Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Unit Economics and Business Growth</h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Legal Compliance and Declarations</h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4>About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">
                  Identity and Business Verification
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="">
                      Business Name<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="businessName"
                      placeholder="Online Shopping"
                      className="form-control"
                      value={formData.businessName}
                      onChange={handleChange}
                    />
                    {errors.businessName && (
                      <p className="error-msg">{errors.businessName}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Founder's Name<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="founderName"
                      placeholder="John Doe"
                      className="form-control"
                      value={formData.founderName}
                      onChange={handleChange}
                    />
                    {errors.founderName && (
                      <p className="error-msg">{errors.founderName}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Date of Birth (for age verification)
                      <span className="required-sign">*</span>
                    </label>
                    <input
                      type="date"
                      name="dob"
                      placeholder="mm/dd/yyyy"
                      className="form-control"
                      value={formData.dob}
                      max={getTodayDate()}
                      onChange={handleChange}
                    />
                    {errors.dob && <p className="error-msg">{errors.dob}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Business Email Address
                      <span className="required-sign">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="example@udharaa.com"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Phone Number<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="(9999) 99999999"
                      className="form-control"
                      name="phoneNumber"
                      maxLength={16}
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {errors.phoneNumber && (
                      <p className="error-msg">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Business Registration Number (if applicable)
                    </label>
                    <input
                      type="text"
                      placeholder="00UD0"
                      className="form-control"
                      name="businessRegistrationNumber"
                      value={formData.businessRegistrationNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group custom-radio-btn">
                    <p>
                      My business has generated more than INR 15 lakhs in
                      lifetime revenue?<span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="lifetimeRevenue"
                            value="Yes"
                            checked={
                              formData.lifetimeRevenue == "Yes" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="lifetimeRevenue"
                            value="No"
                            checked={
                              formData.lifetimeRevenue == "No" ? true : false
                            }
                            onChange={e => setErrorsrupee(true)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.lifetimeRevenue && (
                      <p className="error-msg">{errors.lifetimeRevenue}</p>
                    )}
                  </div>
                  <div className="form-group full">
                    <div className="custom-file">
                      <p>
                        Please upload bank statements, payment processor
                        statements, tax returns, or an accountant's certificate
                        stating your revenue (a minimum of INR 15 lakhs in
                        lifetime revenue is required).
                        <span className="required-sign">*</span>
                      </p>
                      <ul>
                        <li>
                          <h6>File Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="bankStatements"
                            name="bankStatements"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="bankStatements"
                          >
                            {getFileName(formData.bankStatements)
                              ? getFileName(formData.bankStatements).length > 20
                                ? `${getFileName(formData.bankStatements).slice(
                                    0,
                                    20
                                  )}...`
                                : getFileName(formData.bankStatements)
                              : "Upload File"}
                          </label>
                        </li>
                        <p className="custom-pages-p">
                          *Can add maximum of 10 pages
                        </p>
                      </ul>
                      {errors.bankStatements && (
                        <p className="error-msg">{errors.bankStatements}</p>
                      )}
                    </div>
                  </div>
                  <h5>Physical Business Address</h5>
                  <div className="form-group">
                    <label htmlFor="">
                      Country<span className="required-sign">*</span>
                    </label>
                    <select
                      name="country"
                      className="form-control"
                      placeholder={
                        formData.country != ""
                          ? formData.country
                          : "Select country"
                      }
                      value={formData.country}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        {formData.country ? formData.country : "---Select---"}
                      </option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <p className="error-msg">{errors.country}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      City<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {errors.city && <p className="error-msg">{errors.city}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      State<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                    />
                    {errors.state && (
                      <p className="error-msg">{errors.state}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Postal Code<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                      onMouseLeave={getLonglat}
                      maxLength={10}
                    />
                    {errors.postalCode && (
                      <p className="error-msg">{errors.postalCode}</p>
                    )}
                  </div>
                </form>
                <div className="btn-box flex space-between">
                  <a onClick={(e)=>gotostartupsignup(e)}  className="cta-btn back-btn">
                    Back
                  </a>
                  {loading ? (
    <span className="cta-btn blue-btn" style={{ cursor: 'not-allowed' }}>
        <div style={{ marginLeft: "-30%" }}>
            <Loaderbutton />{" "}
        </div>
    </span>
) : (
    <Link onClick={handleSubmit} className="cta-btn blue-btn">
        Next
    </Link>
)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep3;
