/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import { isJsonString, setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function SignupStep5() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    revenueModel: "",
    revenueModelother: "",
    marketAnalysis: "",
    teamBackground: "",
    previousFunding: "",
    fundingDetails: "",
    businessGoals: "",
    legalStructure: "",
    legalStructureother: "",
    supportNeeds: "",
    supportNeedOthers: "",
  });
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [errors, setErrors] = useState({});
  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData(prevFormData => {
        const currentData = prevFormData[name];
        const currentArray = Array.isArray(currentData)
          ? currentData
          : currentData
          ? [currentData]
          : [];
        const newValues = checked
          ? [...currentArray, value]
          : currentArray.filter(v => v !== value);

        return {
          ...prevFormData,
          [name]: newValues,
        };
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (formData?.revenueModel == null) {
      newErrors.revenueModel = "Revenue Model  is required.";
    }

    if (
      !formData?.revenueModelother &&
      formData?.revenueModel?.includes("Other")
    ) {
      newErrors.revenueModelother = "Revenue Model Details are required.";
    }
    // if (!formData.marketAnalysis) {
    //   newErrors.marketAnalysis = "Market Analysis is required.";
    // }
    if (!formData.teamBackground) {
      newErrors.teamBackground = "Team Background is required.";
    }
    if (!formData.previousFunding) {
      newErrors.previousFunding = "Previous Funding is required.";
    }
    if (!formData.fundingDetails && formData.previousFunding === "Yes") {
      newErrors.fundingDetails = "Funding Details are required.";
    }
    if (!formData.businessGoals) {
      newErrors.businessGoals = "Business Goals is required.";
    }
    if (!formData.legalStructure) {
      newErrors.legalStructure = "Legal Structure is required.";
    }
    if (!formData.legalStructureother && formData.legalStructure === "Other") {
      newErrors.legalStructureother = "Legal Structure is required.";
    }
    if (!formData.supportNeeds) {
      newErrors.supportNeeds = "Support Needs is required.";
    }

    if (!formData.supportNeedOthers && formData.supportNeeds === "Other") {
      newErrors.supportNeedOthers = "Support Details are required.";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = {
        revenue_model: JSON.stringify(formData.revenueModel),
        market_analist:
          formData.marketAnalysis != null ? formData.marketAnalysis : "",
        previous_funding: formData.previousFunding,
        team_backGround: formData.teamBackground,
        business_goal: formData.businessGoals,
        legal_structure: formData.legalStructure,
        support_needs: formData.supportNeeds,
        note: formData.fundingDetails,
        other:
          formData.supportNeedOthers != null ? formData.supportNeedOthers : "",
        otherrevnue:
          formData.revenueModelother != null ? formData.revenueModelother : "",
        otherlegal:
          formData.legalStructureother != null
            ? formData.legalStructureother
            : "",
      };

      post(`${apiurl}startups/additionalQuestions/${Userid}`, data)
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/unit-economics-business-growth");
              navigate("/unit-economics-business-growth");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          const startupData = response?.data?.startups;
          setFormData({
            revenueModel: isJsonString(startupData?.revenue_model)
              ? JSON.parse(startupData?.revenue_model)
              : startupData?.revenue_model,
            marketAnalysis: startupData?.market_analist,
            teamBackground: startupData?.team_backGround,
            previousFunding: startupData?.previous_funding,
            businessGoals: startupData?.business_goal,
            legalStructure: startupData?.legal_structure,
            supportNeeds: startupData?.support_needs,
            fundingDetails: startupData?.note,
            supportNeedOthers: startupData?.other,
            legalStructureother: startupData?.otherlegal,
            revenueModelother: startupData?.otherrevnue,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Additional questions</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Identity and Business Verification
                  </h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4>Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Unit Economics and Business Growth</h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Legal Compliance and Declarations</h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4>About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">Additional Questions</h5>
                <form action="">
                  <div className="form-group">
                    <h5>Revenue Model</h5>
                    <label htmlFor="">
                      What is your business’s revenue model?
                      <span className="required-sign">*</span>
                    </label>
                    <div className="custom-check-box">
                      <ul>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Subscription-based"
                                checked={
                                  formData?.revenueModel?.includes(
                                    "Subscription-based"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/Subscription-based.svg"
                                alt="img"
                              />
                              Subscription-based
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Transaction fee-based"
                                checked={
                                  formData?.revenueModel?.includes(
                                    "Transaction fee-based"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/transaction-fee-based.svg"
                                alt="img"
                              />
                              Transaction fee-based
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Freemium"
                                checked={
                                  formData?.revenueModel?.includes("Freemium")
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/Freemium.svg" alt="img" />
                              Freemium
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Advertising"
                                checked={
                                  formData?.revenueModel?.includes(
                                    "Advertising"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/advertising.svg" alt="img" />
                              Advertising
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Direct sales"
                                checked={
                                  formData?.revenueModel?.includes(
                                    "Direct sales"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/direct-sales.svg"
                                alt="img"
                              />
                              Direct sales
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="revenueModel"
                                value="Other"
                                checked={
                                  formData?.revenueModel?.includes("Other")
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/direct-sales.svg"
                                alt="img"
                              />
                              <p>
                                Other <small>(Please specify)</small>
                              </p>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      {errors.revenueModel && (
                        <p className="error-msg">{errors.revenueModel}</p>
                      )}
                    </div>
                    {formData?.revenueModel?.includes("Other") && (
                      <div className="form-group">
                        <input
                          type="text"
                          name="revenueModelother"
                          placeholder="Please specify"
                          className="form-control"
                          value={formData.revenueModelother}
                          onChange={handleChange}
                        />
                        {errors.revenueModelother && (
                          <p className="error-msg">
                            {errors.revenueModelother}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <h5>Market Analysis</h5>
                    <label htmlFor="">
                      Who are your main competitors, and what is your
                      competitive advantage?
                    </label>
                    <textarea
                      type="text"
                      name="marketAnalysis"
                      placeholder=""
                      className="form-control"
                      value={formData.marketAnalysis}
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    />
                    {errors.marketAnalysis && (
                      <p className="error-msg">{errors.marketAnalysis}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <h5>Team Background</h5>
                    <label htmlFor="">
                      Please provide information about the key members of your
                      team and their roles.
                      <span className="required-sign">*</span>
                    </label>
                    <textarea
                      name="teamBackground"
                      placeholder=""
                      className="form-control"
                      value={formData.teamBackground}
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    />
                    {errors.teamBackground && (
                      <p className="error-msg">{errors.teamBackground}</p>
                    )}
                  </div>
                  <div className="form-group custom-radio-btn">
                    <h5>Previous Funding</h5>
                    <p>
                      Have you received any previous investments?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="previousFunding"
                            value="Yes"
                            onChange={handleChange}
                            checked={
                              formData.previousFunding == "Yes" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="previousFunding"
                            value="No"
                            onChange={handleChange}
                            checked={
                              formData.previousFunding == "No" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  {errors.previousFunding && (
                    <p className="error-msg">{errors.previousFunding}</p>
                  )}
                  {formData.previousFunding == "Yes" && (
                    <div className="form-group">
                      <label htmlFor="">
                        Please specify who invested and what were the terms{" "}
                        <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        name="fundingDetails"
                        placeholder="..."
                        className="form-control"
                        value={formData.fundingDetails}
                        onChange={handleChange}
                      />
                      {errors.fundingDetails && (
                        <p className="error-msg">{errors.fundingDetails}</p>
                      )}
                    </div>
                  )}

                  <div className="form-group">
                    <h5>Business Goals</h5>
                    <label htmlFor="">
                      What are your short-term and long-term goals for the
                      business?<span className="required-sign">*</span>
                    </label>
                    <textarea
                      type="text"
                      name="businessGoals"
                      placeholder=""
                      className="form-control"
                      value={formData.businessGoals}
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    />
                    {errors.businessGoals && (
                      <p className="error-msg">{errors.businessGoals}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <h5>Legal Structure</h5>
                    <p>
                      What is the legal structure of your business?
                      <span className="required-sign">*</span>
                    </p>
                    <div className="custom-check-box">
                      <ul>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Sole Proprietorship"
                                checked={
                                  formData.legalStructure ==
                                  "Sole Proprietorship"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img
                                src="src/images/sole-proprietorship.svg"
                                alt="img"
                              />
                              Sole Proprietorship
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Proprietorship"
                                checked={
                                  formData.legalStructure == "Proprietorship"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/partnership.svg" alt="img" />
                              Partnership
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Corporation"
                                checked={
                                  formData.legalStructure == "Corporation"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/corporation.svg" alt="img" />
                              Corporation
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Limited Liability Company (LLC)"
                                checked={
                                  formData.legalStructure ==
                                  "Limited Liability Company (LLC)"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/llc.svg" alt="img" />
                              Limited Liability Company (LLC)
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="radio"
                                name="legalStructure"
                                value="Other"
                                checked={
                                  formData.legalStructure == "Other"
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/other.svg" alt="img" />
                              Other
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                      {errors.legalStructure && (
                        <p className="error-msg">{errors.legalStructure}</p>
                      )}
                      {formData.legalStructure === "Other" && (
                        <div className="form-group">
                          <input
                            type="text"
                            name="legalStructureother"
                            placeholder="Please specify"
                            className="form-control"
                            value={formData.legalStructureother}
                            onChange={handleChange}
                          />
                          {errors.legalStructureother && (
                            <p className="error-msg">
                              {errors.legalStructureother}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group custom-radio-btn">
                    <h5>Support Needs</h5>
                    <p>
                      Beyond funding, what other support are you looking for?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Mentorship
                          <input
                            type="radio"
                            name="supportNeeds"
                            value="Mentorship"
                            onChange={handleChange}
                            checked={
                              formData.supportNeeds == "Mentorship"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Network access
                          <input
                            type="radio"
                            name="supportNeeds"
                            value="Network access"
                            onChange={handleChange}
                            checked={
                              formData.supportNeeds == "Network access"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Strategic partnerships
                          <input
                            type="radio"
                            name="supportNeeds"
                            value="Strategic partnerships"
                            onChange={handleChange}
                            checked={
                              formData.supportNeeds === "Strategic partnerships"
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Other
                          <input
                            type="radio"
                            name="supportNeeds"
                            value="Other"
                            onChange={handleChange}
                            checked={
                              formData.supportNeeds == "Other" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  {errors.supportNeeds && (
                    <p className="error-msg">{errors.supportNeeds}</p>
                  )}

                  {formData.supportNeeds === "Other" && (
                    <div className="form-group">
                      <input
                        type="text"
                        name="supportNeedOthers"
                        placeholder="Please specify"
                        className="form-control"
                        value={formData.supportNeedOthers}
                        onChange={handleChange}
                      />
                      {errors.supportNeedOthers && (
                        <p className="error-msg">{errors.supportNeedOthers}</p>
                      )}
                    </div>
                  )}
                </form>
                <div className="btn-box flex space-between">
                  <Link
                    to="/identity-business-verfication2"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton />{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep5;
