/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getItem, removeItem, setItem } from "./reusable";
import { ChatContext } from "../Context/ChatContext";
import Profile from "./getProfile";
import { toast } from "react-toastify";
import { get } from "../services/services";
import { apiurl } from "../maincomponent/Url";
import Notifications from "./Notifications";
import {
  home,
  watchlist,
  investorprofile,
  startupprofile,
  accountsetting,
  investordashboard,
} from "../Routes/Routes";
function Header() {
  const navigate = useNavigate();
  const userloginref = useRef();
  const userType = getItem("userType");
  const { contractSigned, unreadCounts, socket } = useContext(ChatContext);
  const [menuActive, setMenuActive] = useState(false);
  const token = getItem("token");
  const [User, setUser] = useState({});
  const [openhumberburg, setOpenhumberburg] = useState(false);
  const [notifactionOpen, setNotifactionOpen] = useState(false);
  const [modalclose, setModalclose] = useState(true);
  const [notifactiondata, Setnotificationdata] = useState();
  const [profiledisapproved, setProfiledisapproved] = useState(false);

  const menuToggle = () => {
    setMenuActive(!menuActive);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await Profile();
      if (profile) {
        setUser(profile);
      }
    };
    if (getItem("UserId")) {
      fetchProfile();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      const menu = document.getElementById("menu");
      if (
        menu &&
        !menu.contains(event.target) &&
        userloginref.current &&
        !userloginref.current.contains(event.target)
      ) {
        setMenuActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuActive]);

  const contractviewpage = e => {
    e.preventDefault();

    if (contractSigned) {
      navigate("/privacy-contract-view");
    } else {
      if(userType != "StartUp"){
        toast.error("Please sign the contract to view the startup listings");
        navigate("/privacy-contract");
      }else{
        
        toast.error("First please sign the contract to connect with investor");
        navigate("/privacy-contract");
      }
 
    
    }
  };

  const openHumberburgtoggle = () => {
    if (!openhumberburg) {
      document.body.classList.toggle("scroll-stop");

      document.querySelector("header .toggle-btn").classList.add("active");
      document.documentElement.classList.toggle("scroll-stop");
      const nav = document.querySelector("header nav");
      if (nav) {
        nav.classList.add("active");
      }
    } else {
      document.body.classList.remove("scroll-stop");

      document.querySelector("header .toggle-btn").classList.remove("active");
      document.documentElement.classList.remove("scroll-stop");
      const nav = document.querySelector("header nav");
      if (nav) {
        nav.classList.remove("active");
      }
    }

    setOpenhumberburg(!openhumberburg);
  };

  const notifactionOpenFunction = () => {
    if (notifactionOpen) {
      get(`${apiurl}users/updateUserStatus/${getItem("UserId")}`).then(
        response => {
          setModalclose(false);
        }
      );
    }
    setModalclose(true);
    document.body.classList.remove("scroll-stop");

    document.querySelector("header .toggle-btn").classList.remove("active");
    document.documentElement.classList.remove("scroll-stop");
    const nav = document.querySelector("header nav");
    if (nav) {
      nav.classList.remove("active");
    }
    setNotifactionOpen(!notifactionOpen);
    if (openhumberburg) {
      setOpenhumberburg(false);
    }
  };

  const getAllUserNotification = () => {
    if (getItem("UserId")) {
      get(
        `${apiurl}users/getAllUserNotification/${getItem(
          "UserId"
        )}?page=1&limit=10`
      )
        .then(response => {
          Setnotificationdata(response?.data?.list);
          if (
            getItem("notificationrejectprofile") === "" &&
            getItem("notificationrejectprofile") != "true"
          ) {
            setItem("notificationrejectprofile", "true");
          }
          if (
            getItem("windowreloadreject") === "" ||
            (getItem("windowreloadreject") != "true" &&
              response?.data?.list[0].notificationType != "Profile Rejected")
          ) {
            setItem("windowreloadreject", "true");
          }

          if (
            getItem("windowreloadapproval") === "" ||
            (getItem("windowreloadapproval") != "true" &&
              response?.data?.list[0].notificationType != "Sign contract")
          ) {
            setItem("windowreloadapproval", "true");
          }

          if (
            response?.data?.list[0].notificationType === "Profile Rejected" &&
            getItem("windowreloadreject") === "true"
          ) {
            setItem("windowreloadreject", "false");
            window.location.reload();
          }
          // console.log(response?.data?.list[0].notificationType==="Sign contract")
          if (
            response?.data?.list[0].notificationType === "Sign contract" &&
            getItem("windowreloadapproval") === "true"
          ) {
            // console.log('approved')
            setItem("windowreloadapproval", "false");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }

          if (
            response?.data?.list[0].notificationType === "Profile Rejected" &&
            getItem("notificationrejectprofile") === "true"
          ) {
            setProfiledisapproved(true);
          } else if (
            response?.data?.list[0].notificationType != "Profile Rejected"
          ) {
            setProfiledisapproved(false);
            setItem("notificationrejectprofile", "true");
          }
        })
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    getAllUserNotification();
  }, []);

  useEffect(() => {
    if (!modalclose) {
      getAllUserNotification();
      setModalclose(true);
    }
  }, [modalclose]);

  useEffect(() => {
    if (socket == null) return;
    socket.on("new_user_notification", () => {
      getAllUserNotification();
    });

    return () => {
      socket?.off("new_user_notification");
    };
  }, [socket]);
  useEffect(() => {
    let url = localStorage.getItem("url")?.replaceAll('"',"")

    if(((localStorage.getItem("token") && url == "/startup-profile")||(window.location.pathname===("/privacy-policy")||window.location.pathname===("/support")||window.location.pathname===("/about-us")||window.location.pathname===("/terms&conditions"))) || ((localStorage.getItem("token") && url == "/investor-dashboard")||(window.location.pathname===("/privacy-policy")||window.location.pathname===("/support")||window.location.pathname===("/about-us")||window.location.pathname===("/terms&conditions")))) {
    } else {
      navigate(url)
    }
  }, []);
  return (
    <>
      {profiledisapproved && (
        <div className="popup-container-custom">
          <div className="popup-wrapper pop-open notificationreject" id="">
            <div className="top-head-box flex space-between">
              <h5> Your Profile has been disapproved</h5>
              <div
                className="pop-cross-btn"
                onClick={e => {
                  setProfiledisapproved(false);
                  setItem("notificationrejectprofile", "false");
                }}
              >
                <img src="src/images/cross-icon.svg" alt="" />
              </div>
            </div>
            <div className="inner-poupup">
              <p className="profile-disapprovedh5">
                {notifactiondata && notifactiondata[0].message}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className={`menu ${menuActive ? "active" : ""}`} id="menu">
        <h6 className="menu-name">
          {User?.user_id?.name || User?.name}
          <br />
          <span>{User?.user_id?.email || User?.email}</span>
        </h6>
        <ul className="menu-options">
          <li>
            <Link
              to={userType != "StartUp" ? investorprofile : startupprofile}
              className="menu-text"
            >
              My profile
            </Link>
          </li>
          <li style={{ display: userType != "StartUp" ? "" : "none" }}>
            <Link to={investordashboard} className="menu-text">
              Business List
            </Link>
          </li>
          <li style={{ display: userType != "StartUp" ? "" : "none" }}>
            <Link to={watchlist} className="menu-text">
              My Favourite List
            </Link>
          </li>
          <li>
            <a onClick={e => contractviewpage(e)} className="menu-text">
              My Contract
            </a>
          </li>
          <li>
            <Link to={accountsetting} className="menu-text">
              Account settings
            </Link>
          </li>

          <li>
            <a
              onClick={e => {
                socket?.disconnect();
                e.preventDefault();
                removeItem("token");
                removeItem("userType");
                removeItem("UserId");
                removeItem("url");
                navigate("/login");
              }}
              href={home}
              className="menu-text"
            >
              Sign out
            </a>
          </li>
        </ul>
      </div>
      {notifactionOpen && (
        <Notifications
          notifactionOpenFunction={notifactionOpenFunction}
          contractSigned={contractSigned}
          notificationdata={notifactiondata}
        />
      )}
      <header
        className="site-header inner-header flex"
        style={{ alignItems: "center", height: "11vh" }}
      >
        <div className="container">
          <div className="nav-box flex space-between">
            <div className="site-branding">
              <a href="/">
                <img src="src/images/site-logo.svg" alt="site-logo" />
              </a>
            </div>
            <ul className="flex align-center normalnav">
              {" "}
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/support">Support</a>
              </li>
              {/* <li>
                <a href="/Contact-us">Contact us</a>
              </li>{" "} */}
            </ul>

            <div
              className="toggle-btn"
              style={{
                right:
                  (getItem("userType") != "" || getItem("UserId") != "") &&
                  !openhumberburg
                    ? "68px"
                    : "68px",
              }}
              onClick={openHumberburgtoggle}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

            <nav style={{ right: openhumberburg ? "0" : "" }}>
              <ul className="flex align-center custom-header">
                <div className="navlogo">
                  <Link to="/">
                    <img src="src/images/site-logo.svg" alt="" />
                  </Link>
                </div>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/support">Support</a>
                </li>

                {/* <li>
                  <a href="/">Services</a>
                </li> */}
                <li style={token != "" ? {} : { display: "none" }}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (contractSigned) {
                        navigate("/messenger");
                      } else {
                        toast.error(
                          "Please sign your contract to view the chats"
                        );
                        navigate("/privacy-contract");
                      }
                    }}
                  >
                    {unreadCounts ? (
                      <>
                        Message <span className="required-sign">*</span>
                      </>
                    ) : (
                      <>Message</>
                    )}
                  </a>
                </li>
                <li style={token != "" ? {} : { display: "none" }}>
                  <a
                    className="open_notifaction"
                    onClick={notifactionOpenFunction}
                  >
                    {notifactiondata &&
                    notifactiondata?.filter(notification => !notification.seen)
                      .length != 0 ? (
                      <>
                        Notifications <span className="required-sign">*</span>
                      </>
                    ) : (
                      <>Notification</>
                    )}
                  </a>
                </li>
                {/* <li>
                  <a href="/Contact-us">Contact us</a>
                </li> */}
                <li
                  style={token != "" ? { display: "none" } : {}}
                  className="btn-box headerli"
                >
                  <Link
                    to="/login"
                    className="cta-btn  header-btn humberburgbtn"
                  >
                    Login
                  </Link>
                  <Link
                    to="/signup"
                    className="cta-btn btn-orange header-btn humberburgbtn"
                  >
                    Signup
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="after-login" ref={userloginref}>
              <ul className="flex align-center">
                <li
                  style={token != "" ? { display: "none" } : {}}
                  className="btn-box headerli"
                >
                  <Link to="/login" className="cta-btn btn-blue header-btn">
                    Login
                  </Link>
                  <Link to="/signup" className="cta-btn btn-orange header-btn">
                    Signup
                  </Link>
                </li>
                <li
                  style={{
                    marginRight: "10px",
                    display: token == "" ? "none" : "",
                  }}
                  className="user flex"
                  onClick={menuToggle}
                >
                  {userType === "StartUp" ? (
                    <img
                      className="profile"
                      src={User?.founderPhoto}
                      alt=""
                      onClick={menuToggle}
                    />
                  ) : (
                    <img
                      className="profile"
                      src={User?.profile_picture}
                      alt=""
                      onClick={menuToggle}
                    />
                  )}
                </li>
                <Link
                  style={{
                    marginRight: "10px",
                    display: token == "" ? "none" : "",
                  }}
                  className="user-name mobilename"
                  onClick={menuToggle}
                >
                  {User?.user_id?.name || User?.name}
                </Link>
                <img
                  style={{
                    marginRight: "10px",
                    display: token == "" ? "none" : "",
                  }}
                  src="src/images/ChevronDown.png"
                  alt=""
                />
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
