/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef, useContext, useCallback  } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Footer2 from "../../common/Footer2";
import Header2 from "../../common/Header2";
import { apiurl } from "../Url";
import { getItem } from "../../common/reusable";
import SignatureCanvas from "react-signature-canvas";
import { post, get } from "../../services/services";
import { ChatContext } from "../../Context/ChatContext";
import Loader from "../../common/Loader";
import Loaderbutton from "../../common/loaderbutton";

function InvestorPrivacy() {
  const [privacy, setPrivacy] = useState("");
  const userType = getItem("userType");
  const [isPadEmpty, setIsPadEmpty] = useState(true);
  const [userid, setUserid] = useState(localStorage.getItem("UserId"));
  const [acceptcontracterror, setAcceptcontracterror] = useState(false);
  const [acceptcontractsignerror, setAcceptcontractsignerror] = useState("");
  const [acceptcontract, setAcceptcontract] = useState(false);
  const [signatureImageURL, setSignatureImageURL] = useState(null);
  const { setContractSigned } = useContext(ChatContext);
  const [loading, Setloading] = useState(true);
  const [loadingbutton,SetLoadingbutton]=useState(false);
  let signatureRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    Setloading(true);
    let apifetchurl;

    if (userType != "StartUp") {
      apifetchurl = `${apiurl}admin/showPrivacyContractualAgreement?type=investor`;
    } else {
      apifetchurl = `${apiurl}admin/showPrivacyContractualAgreement?type=startup`;
    }

    get(apifetchurl)
      .then(response => {
        if (!response) {
        } else {
          setPrivacy(response.data.data);
          Setloading(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  // const trimSignature = () => {
  //   if (signatureRef?.current?.isEmpty()) {
  //     return;
  //   }
  //   const trimmedCanvas = signatureRef?.current?.getTrimmedCanvas();
  //   const imageDataURL = trimmedCanvas?.toDataURL("image/png");
  // };

  const handleOnChange = useCallback(() => {
    setAcceptcontracterror("");
    const canvas = signatureRef.current.getCanvas();
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    const hasNonTransparentPixels = [...imageData].some((pixel, index) => {
      return (index + 1) % 4 === 0 && pixel !== 0;
    });
    setIsPadEmpty(!hasNonTransparentPixels);
  }, []);
  const [ipAddress, setIpAddress] = useState("");
  const [locationData, setLocationData] = useState({});
  const [formattedTimestamp, setFormattedTimestamp] = useState("");
  const formatTimestampTo24Hour = timestamp => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-GB", { hour12: false });
  };
  useEffect(() => {
    const fetchLocationAndTimestamp = async () => {
      try {
        const timestamp = new Date().toISOString();
        const formattedTimestamp = formatTimestampTo24Hour(timestamp);

        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;

        const locationResponse = await axios.get(
          `https://ipapi.co/${ipAddress}/json/`
        );
        const locationData = locationResponse.data;

        setIpAddress(ipAddress);
        setLocationData(locationData);
        setFormattedTimestamp(formattedTimestamp);
        Setloading(false);
      } catch (error) {
        console.error(
          "An error occurred while fetching location and timestamp:",
          error
        );
      }
    };

    fetchLocationAndTimestamp();
  }, []);

  const investorprivacysubmit = async () => {
    try {
      if (acceptcontract != true) {
        setAcceptcontracterror("Please accept Privacy policy");
        return;
      }

// alert('investor')

    if (signatureRef.current!=undefined &&signatureRef.current.isEmpty()&& !userType != "StartUp") {
      setAcceptcontractsignerror("Please Sign to this contract");
      // console.log('investor')
       return;
    }
     SetLoadingbutton(true);
   
      const cleanUserid = String(userid).replace(/['"]+/g, "");
      const canvas = signatureRef.current!=undefined &&signatureRef.current.getCanvas();
      const signatureImage = canvas?canvas.toDataURL("image/png"):"";
      const base64Data = signatureImage.replace(/^data:image\/png;base64,/, "");
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });
      const imagefile = new File([blob], `${cleanUserid}sign.png`, {
        type: "image/png",
      });

      setSignatureImageURL(base64Data);
      const timestamp = new Date().toISOString();
      const formdata = new FormData();
      formdata.append("title", privacy?.title);
      formdata.append("message", privacy?.message);
      formdata.append("sign", imagefile);
      formdata.append("ip", ipAddress);
      formdata.append("location", JSON.stringify(locationData));
      formdata.append("timestamp", formattedTimestamp);
      formdata.append("base64", base64Data);
      let apifetchurl;

      if (userType != "StartUp") {
        apifetchurl = `investors/acceptContract/${cleanUserid}`;
      } else {
        apifetchurl = `startups/acceptContract/${cleanUserid}`;
      }
      const response = await post(apifetchurl, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!response) {
      } else {
        if (!response.data.status) {
          toast.error(response.data.message);
        } else {
          setContractSigned(true);
          toast.success(response?.data?.message);
          SetLoadingbutton(false);
          if (userType != "StartUp") {
            navigate("/investor-dashboard");
          } else {
            navigate("/startup-profile");
          }
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  // useEffect(() => {
  //   const userType = JSON.parse(localStorage.getItem("userType"));
  //   if (userType != null) {
  //     if (userType != "Investor") {
  //       navigate("/");
  //     }
  //   }
  // }, []);
  return (
    <>
      <div
        className="loading-overlay"
        style={{ display: loading ? "block" : "none" }}
      >
        <Loader />
      </div>
      <Header2 />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Privacy contract</title>
      </Helmet>
      <div className="bredcrum-sec">
        <div className="container-sm">
          <div className="bredcrum-box flex align-center">
            <div className="left-box">
              <h5>Non Circumvention Contract</h5>
              <ul className="flex">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/">Startups business List</a>
                </li>
                <li>Non Circumvention Contract</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="privacy-Sec">
        <div className="container-sm">
          <div className="content-box">
            <div className="content-inner-box">
              <div className="logopctop">
                <img src="src/images/pctop.png" alt="" />
              </div>
              <div className="logopcbottom">
                <img src="src/images/pcbottom.png" alt="" />
              </div>
              <div className="logo">
                <a href="/">
                  <img src="src/images/site-logo.svg" alt="" />
                </a>
              </div>
              <h2>{privacy?.title}</h2>
              {/* {privacy &&
                privacy.map((data, i) => <p key={i}>{data.message}</p>)} */}
              <p
                dangerouslySetInnerHTML={{
                  __html: privacy?.message,
                }}
              >
                {/* Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit", comes from a line in
                section 1.10.32. */}
              </p>

              <form action="">
                <div className="form-group checkbox contractlabel">
                  <label className="flex align-center">
                  {
                  userType != "StartUp"?(<>By clicking the "I agree" button, the investor confirms their acceptance of all the terms and conditions stated in this agreement</>):(<>   I Accept the{" "}
                    <a href="/privacy-policy" target="_blank">Privacy Policy</a></>)}
                  
                    <input type="checkbox" />
                    <span
                      className="checkmark"
                      onClick={() => {
                        setAcceptcontract(!acceptcontract);
                        setAcceptcontracterror("");
                      }}
                      value={acceptcontract}
                      checked={acceptcontract == true}
                    ></span>
                  </label>
                  {acceptcontracterror && (
                    <p className="error-msg">{acceptcontracterror}</p>
                  )}
                </div>
                {
                  userType != "StartUp"?(""):(<>
                       <h4>Signature </h4>
                <div
                  style={{ borderBottom: "none" }}
                  className="sign-box"
                ></div>
                <div
                  className="sigCanvas"
                  style={{ border: "2px dashed grey" }}
                >
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{ className: "sigCanvas" }}
                    onEnd={handleOnChange}
                  />
                  {acceptcontractsignerror && (
                    <p className="error-msg contracterror">
                      {acceptcontractsignerror}
                    </p>
                  )}
                </div>
                <img
                  onClick={() => signatureRef?.current?.clear()}
                  className="retry"
                  src="src/images/reload.png"
                  alt=""
                />
                  </>)
                }
           
                {/* <p onClick={()=>signatureRef?.current?.clear()}>Retry</p> */}
              </form>
              <div className="privacycontractipdiv">
                <div className="privacycontract-ip">
                  <h4>Ip address:</h4>
                  <p>{ipAddress}</p>
                </div>
                <div className="privacycontract-ip">
                  <h4>User location:</h4>
                  <p>{locationData?.region}</p>
                </div>
                <div className="privacycontract-ip">
                  <h4>Date and Time:</h4>
                  <p>{formattedTimestamp}</p>
                </div>
              </div>
            </div>
          </div>
          <a
            style={{ marginTop: "15px", cursor: "pointer" }}
            className="cta-btn"
            onClick={investorprivacysubmit}
          >
              {loadingbutton ? (
                      <div style={{ marginLeft: "-30%" }}>
                      <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Send"
                    )}
            
          </a>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
export default InvestorPrivacy;
