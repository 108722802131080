/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { post, get } from "../../services/services";
import  { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";
function InvestorSignupStep4() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    profilePicture: "",
    bio: "",
    linkedInURL: "",
    personalWebsiteURL: "",
    generalQuestions: "",
    agreeToTerms: false,
    generalQuestionsother: "",
  });
  const [getProfilePicture, setGetProfilePicture] = useState("");
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [errors, setErrors] = useState({});
  const handleChange = e => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const maxSize = 5 * 1024 * 1024;

      if (file.size > maxSize) {
        toast.warn("File size should not exceed 5MB", {
          position: "top-center",
        });
      } else {
        setFormData({
          ...formData,
          [name]: file,
        });
        setGetProfilePicture(file.name);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};

    if (!formData.profilePicture && !getProfilePicture) {
      newErrors.profilePicture = "Profile Picture is required.";
    }
    if (!formData.bio) {
      newErrors.bio = "bio is required.";
    } else if (formData.bio.length > 500) {
      newErrors.bio = "Bio cannot exceed 500 characters.";
    }
    if (!formData.generalQuestions) {
      newErrors.generalQuestions = "General Questions is required.";
    } else if (
      formData.generalQuestions === "other" &&
      !formData.generalQuestionsother
    ) {
      newErrors.generalQuestionsother = "Please specificy other option ";
    }
    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = "Agree Terms is required.";
    }
    if (formData.linkedInURL) {
      if (!/^https?:\/\/.*/i.test(formData.linkedInURL)) {
        newErrors.linkedInURL =
          "Linkdein URL must start with https:// or http:// ";
      }
    }
    if (formData.personalWebsiteURL) {
      if (!/^https?:\/\/.*/i.test(formData.personalWebsiteURL)) {
        newErrors.personalWebsiteURL =
          "Personal URL must start with https:// or http:// ";
      }
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = new FormData();
      data.append("bio", formData.bio);
      data.append(
        "linked_in_url",
        formData.linkedInURL != "" && formData.linkedInURL != "undefined"
          ? formData.linkedInURL
          : ""
      );
      data.append(
        "professional_website",
        formData.personalWebsiteURL != "" &&
          formData.personalWebsiteURL != "undefined"
          ? formData.personalWebsiteURL
          : ""
      );
      data.append(
        "general_questions",
        formData.generalQuestions != "" &&
          formData.generalQuestions != "undefined"
          ? formData.generalQuestions
          : ""
      );
      data.append(
        "profile_picture",
        formData.profilePicture != "" && formData.profilePicture != "undefined"
          ? formData.profilePicture
          : ""
      );
      data.append(
        "general_questionsother",
        formData.generalQuestionsother != null
          ? formData.generalQuestionsother
          : ""
      );

      post(`investors/additionINformation/${Userid}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/investor-dashboard");
              navigate("/welcome-investor");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}users/getInvestorProfile/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          setFormData({
            bio: response?.data?.user?.bio,
            // linkedInURL:
            //   response?.data?.user?.linked_in_url != "null"
            //     ? response?.data?.user?.linked_in_url
            //     : "",
            // personalWebsiteURL:
            //   response?.data?.user?.professional_website != "null"
            //     ? response?.data?.user?.professional_website
            //     : "",
            generalQuestions: response?.data?.user?.general_questions,
            generalQuestionsother: response?.data?.user?.general_questionsother,
            // profilePicture:response?.data?.user?.profile_picture,
          });
          setGetProfilePicture(response?.data?.user?.profile_picture);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Additional information</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Personal and Investment Profile
                  </h4>
                  <p>Build Your Investment Profile</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4 className="blurred-text">Identity Verification</h4>
                  <p>Verify Your Identity</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Financial Standing and Accreditation
                  </h4>
                  <p>Confirm Your Financial Status</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Additional Information</h4>
                  <p>Enter Additional Information</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">Additional Information</h5>
                <form action="">
                  <div className="form-group full">
                    <label htmlFor="">
                      Profile Picture<span className="required-sign">*</span>
                    </label>
                    <div className="custom-file">
                      <ul>
                        <li>
                          <h6>Upload Photo</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="passport"
                            name="profilePicture"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="passport"
                          >
                            {getProfilePicture
                              ? getProfilePicture.length > 20
                                ? `${getProfilePicture.slice(0, 20)}...`
                                : getProfilePicture
                              : "Upload a professional profile picture"}
                          </label>
                        </li>
                      </ul>
                      {errors.profilePicture && (
                        <p className="error-msg">{errors.profilePicture}</p>
                      )}
                    </div>
                  </div>
                  <div className="form-group additionalinformationorder">
                    <label htmlFor="">
                      Bio<span className="required-sign">*</span>
                    </label>
                    <textarea
                      name="bio"
                      placeholder="Enter your text here (maximum 500 characters)"
                      className="form-control"
                      value={formData.bio}
                      onChange={handleChange}
                      maxLength={500}
                    ></textarea>
                    {errors.bio && <p className="error-msg">{errors.bio}</p>}
                    <p>
                      *Provide a brief biography highlighting your professional
                      background and investment interests.
                    </p>
                    <div className="character-counter">
                      {500 - (formData.bio ? formData.bio.length : 0)}{" "}
                      characters remaining
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">LinkedIn URL (Optional)</label>
                    <input
                      type="text"
                      placeholder="https://"
                      className="form-control"
                      value={formData.linkedInURL}
                      onChange={handleChange}
                      name="linkedInURL"
                    />
                    <p>
                      *Include the link to your LinkedIn profile for networking
                      and verification purposes.
                    </p>
                    {errors.linkedInURL && (
                      <p className="error-msg">{errors.linkedInURL}</p>
                    )}
                  </div>
                  <div className="form-group additionalinformationorder">
                    <label htmlFor="">Personal Website URL (Optional)</label>
                    <input
                      type="text"
                      placeholder="https://"
                      className="form-control"
                      name="personalWebsiteURL"
                      value={formData.personalWebsiteURL}
                      onChange={handleChange}
                    />
                    <p>
                      *If applicable, provide the URL to your personal or
                      professional website.
                    </p>
                    {errors.personalWebsiteURL && (
                      <p className="error-msg">{errors.personalWebsiteURL}</p>
                    )}
                  </div>
                  <h5>General Questions</h5>
                  <div className="form-group">
                    <label htmlFor="">
                      How did you hear about us?
                      <span className="required-sign">*</span>
                    </label>
                    <select
                      name="generalQuestions"
                      className="form-control"
                      value={formData.generalQuestions}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        ---Select---
                      </option>
                      <option value="search-engine">
                        Search Engine (e.g., Google, Bing)
                      </option>
                      <option value="social-media">
                        Social Media (e.g., Facebook, Instagram)
                      </option>
                      <option value="friend-family">Friend or Family</option>
                      <option value="online-advertisement">
                        Online Advertisement
                      </option>
                      <option value="news-article-blog">
                        News Article or Blog
                      </option>
                      <option value="email-campaign">Email Campaign</option>
                      <option value="event-webinar">Event or Webinar</option>
                      <option value="youtube">YouTube</option>
                      <option value="podcast">Podcast</option>
                      <option value="other">Other (please specify)</option>
                    </select>
                    {errors.generalQuestions && (
                      <p className="error-msg">{errors.generalQuestions}</p>
                    )}
                  </div>
                  {formData.generalQuestions == "other" && (
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Specify other option"
                        className="form-control"
                        name="generalQuestionsother"
                        value={formData.generalQuestionsother}
                        onChange={handleChange}
                      />
                      {errors.generalQuestionsother && (
                        <p className="error-msg">
                          {errors.generalQuestionsother}
                        </p>
                      )}
                    </div>
                  )}
                  <h5>Acceptance of Terms and Conditions</h5>
                  <div className="form-group checkbox">
                    <label className="flex">
                      I agree to the <a href="/terms&conditions" target="_blank" rel="noopener noreferrer">Terms of Service</a>{" "}and{" "}
                      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                      <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                      />
                      {errors.agreeToTerms && (
                        <p className="error-msg">{errors.agreeToTerms}</p>
                      )}
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </form>
                <div className="btn-box flex space-between">
                  <Link
                    to="/financial-standing-accreditation"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default InvestorSignupStep4;
