/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import { isJsonString, setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";
import { removeItem } from "../../common/reusable";

function InvestorSignupStep1() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumber: "",
    address: "",
    occupation: "",
    investmentFocus: [],
    investmentFocusother: "",
    experience: "",
    investmentSize: "",
    roleInInvestments: "",
    previousInvestments: "",
    riskProfile: "",
    expectations: "",
    decisionFactors: [],
    decisionFactorsother: "",
    portfolioStrategy: "",
    portfolioStrategyother: "",
    geographicPreference: "",
  });
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const [errors, setErrors] = useState({});
  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const phoneNumberPattern = /^[+\d\s\-\(\)\.\/]*$/;

    if (name === "phoneNumber") {
      if (!phoneNumberPattern.test(value)) {
        setErrors({ ...errors, [name]: "Alphabets not allowed" });
        return;
      }
    }
    if (name === "previousInvestments" && value.length > 500) {
      setErrors(previousError => ({
        ...previousError,
        previousInvestments: "Word limit is over of 500 words",
      }));
      return;
    }
    if (type === "checkbox") {
      setFormData(prevFormData => {
        const newValues = checked
          ? [...(prevFormData[name] || []), value]
          : (prevFormData[name] || []).filter(v => v !== value);

        return {
          ...prevFormData,
          [name]: newValues,
        };
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    } else if (formData.phoneNumber.length < 6) {
      newErrors.phoneNumber = "Phone Number is not Less then 6 Number.";
    }
    if (!formData.address) {
      newErrors.address = "Address is required.";
    }
    if (!formData.occupation) {
      newErrors.occupation = "Occupation is required.";
    }
    if (!formData?.investmentFocus[0]) {
      newErrors.investmentFocus = "Investment Focus is required.";
    } else if (
      formData?.investmentFocus?.includes("Other") &&
      !formData.investmentFocusother
    ) {
      newErrors.investmentFocusother = "Please specificy other option ";
    }

    if (!formData.experience) {
      newErrors.experience = " Experience is required.";
    }
    if (!formData.investmentSize) {
      newErrors.investmentSize = "Investment Size is required.";
    }
    if (!formData.roleInInvestments) {
      newErrors.roleInInvestments = "Role In Investments is required.";
    }
  // if (formData.previousInvestments.length > 500) {
  //     newErrors.previousInvestments =
  //       "Previous Investments is exceding 500 words";
  //   }
    if (!formData.riskProfile) {
      newErrors.riskProfile = "Risk Profile is required.";
    }
    if (!formData.expectations) {
      newErrors.expectations = "Expectations is required.";
    }
    if (!formData?.decisionFactors[0]) {
      newErrors.decisionFactors = "Decision Factors is required.";
    }
    if (
      !formData.decisionFactorsother &&
      formData.decisionFactors?.includes("Other")
    ) {
      newErrors.decisionFactorsother = "Please specify Other option.";
    }
    if (!formData.portfolioStrategy) {
      newErrors.portfolioStrategy = "Portfolio Strategy is required.";
    } else if (
      formData.portfolioStrategy === "Other" &&
      !formData.portfolioStrategyother
    ) {
      newErrors.portfolioStrategyother = "Please specificy other option ";
    }

    if (!formData.geographicPreference[0] || !formData.geographicPreference) {
      newErrors.geographicPreference = "Geographic Preference is required.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const data = {
        phone_number: formData.phoneNumber,
        address: formData.address,
        occupation: formData.occupation,
        investment_focus: JSON.stringify(formData.investmentFocus),
        experience: formData.experience,
        investment_size: formData.investmentSize,
        role_in_investment: formData.roleInInvestments,
        // previous_investment: formData.previousInvestments,
        risk_profile: formData.riskProfile,
        expectations: formData.expectations,
        decision_factors: JSON.stringify(formData.decisionFactors),
        portfolio_strategy: formData.portfolioStrategy,
        portfolio_strategyother:
          formData.portfolioStrategyother != null
            ? formData.portfolioStrategyother
            : "",
        geographic_preference: formData.geographicPreference[0]
          ? JSON.stringify(formData.geographicPreference)
          : "",
        investment_focusother:
          formData.investmentFocusother != null
            ? formData.investmentFocusother
            : "",
        decision_factorsother:
          formData.decisionFactorsother != null
            ? formData.decisionFactorsother
            : "",
      };

      post(`${apiurl}investors/investorProfile/${Userid}`, data)
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/identity-verification")
              navigate("/identity-verification");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}users/getInvestorProfile/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          setFormData({
            phoneNumber: response?.data?.user?.phone_number,
            address: response?.data?.user?.address,
            occupation: response?.data?.user?.occupation,
            investmentFocus: JSON.parse(response?.data?.user?.investment_focus),
            experience: response?.data?.user?.experience,
            investmentSize: response?.data?.user?.investment_size,
            roleInInvestments: response?.data?.user?.role_in_investment,
            // previousInvestments: response?.data?.user?.previous_investment,
            riskProfile: response?.data?.user?.risk_profile,
            expectations: response?.data?.user?.expectations,
            decisionFactors: JSON.parse(response?.data?.user?.decision_factors),
            portfolioStrategy: response?.data?.user?.portfolio_strategy,
            geographicPreference: isJsonString(
              response?.data?.user?.geographic_preference
            )
              ? JSON.parse(response?.data?.user?.geographic_preference)
              : response?.data?.user?.geographic_preference,
            investmentFocusother: response?.data?.user?.investment_focusother,
            decisionFactorsother: response?.data?.user?.decision_factorsother,
            portfolioStrategyother:
              response?.data?.user?.portfolio_strategyother,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const gotostartupsignup=(e)=>{
    e.preventDefault()
    removeItem("token");
    removeItem("userType");
    removeItem("UserId");
    navigate('/investor-signup')
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Personal investment profile</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex active">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4>Personal and Investment Profile</h4>
                  <p>Build Your Investment Profile</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4>Identity Verification</h4>
                  <p>Verify Your Identity</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4>Financial Standing and Accreditation</h4>
                  <p>Confirm Your Financial Status</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Additional Information</h4>
                  <p>Enter Additional Information</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">Personal and Investment Profile</h5>
                <form action="">
                  <div className="form-group">
                    <label htmlFor="">
                      Phone Number<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="(9999) 99999999"
                      className="form-control"
                      name="phoneNumber"
                      maxLength={16}
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {errors.phoneNumber && (
                      <p className="error-msg">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Address<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="street 40 etc."
                      className="form-control"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <p className="error-msg">{errors.address}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Occupation<span className="required-sign">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Occupation"
                      className="form-control"
                      name="occupation"
                      value={formData.occupation}
                      onChange={handleChange}
                    />
                    {errors.occupation && (
                      <p className="error-msg">{errors.occupation}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <h5>Investment Focus</h5>
                    <label htmlFor="">
                      What are your primary areas of investment interest?
                      <span className="required-sign">*</span>
                    </label>
                    <div className="custom-check-box">
                      <ul>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="investmentFocus"
                                value="Technology"
                                checked={
                                  formData?.investmentFocus?.includes(
                                    "Technology"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/technology.svg" alt="img" />
                              Technology
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="investmentFocus"
                                value="Healthcare"
                                checked={
                                  formData?.investmentFocus?.includes(
                                    "Healthcare"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/healthcare.svg" alt="img" />
                              Healthcare
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="investmentFocus"
                                value="Education"
                                checked={
                                  formData?.investmentFocus?.includes(
                                    "Education"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/education.svg" alt="img" />
                              Education
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="investmentFocus"
                                value="Energy"
                                checked={
                                  formData?.investmentFocus?.includes("Energy")
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/energy.svg" alt="img" />
                              Energy
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                name="investmentFocus"
                                value="Consumer Goods"
                                checked={
                                  formData?.investmentFocus?.includes(
                                    "Consumer Goods"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/consumer.svg" alt="img" />
                              Consumer Goods
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="checkbox">
                            <label className="flex">
                              <input
                                type="checkbox"
                                value="Other"
                                name="investmentFocus"
                                checked={
                                  formData?.investmentFocus?.includes("Other")
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                              />
                              <img src="src/images/other.svg" alt="img" />
                              <p>
                                Other <small>(Please specify)</small>
                              </p>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {errors.investmentFocus && (
                      <p className="error-msg">{errors.investmentFocus}</p>
                    )}
                  </div>
                  {formData.investmentFocus?.includes("Other") && (
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Specify other option"
                        className="form-control"
                        name="investmentFocusother"
                        value={formData.investmentFocusother}
                        onChange={handleChange}
                      />

                      {errors.investmentFocusother && (
                        <p className="error-msg">
                          {errors.investmentFocusother}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="form-group custom-radio-btn">
                    <h5>Experience</h5>
                    <p>
                      What is your experience with startup investments?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Beginner
                          <input
                            type="radio"
                            name="experience"
                            value="Beginner"
                            checked={
                              formData.experience == "Beginner" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Intermediate
                          <input
                            type="radio"
                            value="Intermediate"
                            name="experience"
                            checked={
                              formData.experience == "Intermediate"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Experienced
                          <input
                            type="radio"
                            name="experience"
                            value="Experienced"
                            checked={
                              formData.experience == "Experienced"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Veteran
                          <input
                            type="radio"
                            name="experience"
                            value="Veteran"
                            checked={
                              formData.experience == "Veteran" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.experience && (
                      <p className="error-msg">{errors.experience}</p>
                    )}
                  </div>
                  <div className="form-group custom-radio-btn">
                    <h5>Investment Size</h5>
                    <p>
                      What range of investment are you typically comfortable
                      making?<span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Less than ₹50,00,000
                          <input
                            type="radio"
                            name="investmentSize"
                            value="Less than ₹50,00,000"
                            checked={
                              formData.investmentSize == "Less than ₹50,00,000"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          ₹50,00,000 to ₹100,00,000
                          <input
                            type="radio"
                            name="investmentSize"
                            value="₹50,00,000 to ₹100,00,000"
                            checked={
                              formData.investmentSize == "₹50,00,000 to ₹100,00,000"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          ₹100,00,000 to ₹50,000,000
                          <input
                            type="radio"
                            name="investmentSize"
                            value="₹100,00,000 to ₹500,000,000"
                            checked={
                              formData.investmentSize == "₹100,00,000 to ₹500,000,000"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          More than ₹500,000,000
                          <input
                            type="radio"
                            name="investmentSize"
                            value="More than ₹500,000,000"
                            checked={
                              formData.investmentSize == "More than ₹500,000,000"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.investmentSize && (
                      <p className="error-msg">{errors.investmentSize}</p>
                    )}
                  </div>
                  <div className="form-group custom-radio-btn">
                    <h5>Role in Investments</h5>
                    <p>
                      Do you prefer a passive or active role in your
                      investments?<span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Passive
                          <input
                            type="radio"
                            name="roleInInvestments"
                            value="Passive"
                            checked={
                              formData.roleInInvestments == "Passive"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Active
                          <input
                            type="radio"
                            name="roleInInvestments"
                            value="Active"
                            checked={
                              formData.roleInInvestments == "Active"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.roleInInvestments && (
                      <p className="error-msg">{errors.roleInInvestments}</p>
                    )}
                  </div>
                  {/* <div className="form-group ">
                    <h5>Previous Investments</h5>
                    <p>
                      Can you list some of your previous investments and their
                      outcomes?<span className="required-sign">*</span>
                    </p>
                    <input
                      type="text"
                      name="previousInvestments"
                      className="form-control"
                      value={formData.previousInvestments}
                      onChange={handleChange}
                    />
                        {formData.previousInvestments ? (
                        <>
                          {" "}
                          {500 - formData.previousInvestments.length}{" "}
                          characters remaining
                        </>
                      ) : (
                        ""
                      )}
                    {errors.previousInvestments && (
                      <p className="error-msg">{errors.previousInvestments}</p>
                    )}
                  </div> */}
                  <div className="form-group custom-radio-btn">
                    <h5>Risk Profile</h5>
                    <p>
                      What is your risk tolerance when investing in startups?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Low
                          <input
                            type="radio"
                            name="riskProfile"
                            value="Low"
                            checked={
                              formData.riskProfile == "Low" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Moderate
                          <input
                            type="radio"
                            name="riskProfile"
                            value="Moderate"
                            checked={
                              formData.riskProfile == "Moderate" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          High
                          <input
                            type="radio"
                            name="riskProfile"
                            value="High"
                            checked={
                              formData.riskProfile == "High" ? true : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.riskProfile && (
                      <p className="error-msg">{errors.riskProfile}</p>
                    )}
                  </div>
                  <div className="form-group custom-radio-btn">
                    <h5>Expectations</h5>
                    <p>
                      What are your expectations in terms of return on
                      investment (ROI)?<span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Below 10%
                          <input
                            type="radio"
                            name="expectations"
                            value="Below 10%"
                            checked={
                              formData.expectations == "Below 10%"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          10% to 20%
                          <input
                            type="radio"
                            name="expectations"
                            value="10% to 20%"
                            checked={
                              formData.expectations == "10% to 20%"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          20% to 50%
                          <input
                            type="radio"
                            value="20% to 50%"
                            name="expectations"
                            checked={
                              formData.expectations == "20% to 50%"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          More than 50%
                          <input
                            type="radio"
                            value="More than 50%"
                            name="expectations"
                            checked={
                              formData.expectations == "More than 50%"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.expectations && (
                      <p className="error-msg">{errors.expectations}</p>
                    )}
                  </div>
                  <div className="form-group custom-radio-btn decisionfactors">
                    <h5>Decision Factors</h5>
                    <p>
                      What key factors influence your decision to invest in a
                      startup?<span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Team
                          <input
                            type="checkbox"
                            value="Team"
                            name="decisionFactors"
                            checked={
                              formData?.decisionFactors?.includes("Team")
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Idea
                          <input
                            type="checkbox"
                            name="decisionFactors"
                            value="Idea"
                            checked={
                              formData?.decisionFactors?.includes("Idea")
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Market Size
                          <input
                            type="checkbox"
                            name="decisionFactors"
                            value="Market Size"
                            checked={
                              formData?.decisionFactors?.includes("Market Size")
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Scalability
                          <input
                            type="checkbox"
                            name="decisionFactors"
                            value="Scalability"
                            checked={
                              formData?.decisionFactors?.includes("Scalability")
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Other
                          <input
                            type="checkbox"
                            value="Other"
                            name="decisionFactors"
                            checked={
                              formData?.decisionFactors?.includes("Other")
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.decisionFactors && (
                      <p className="error-msg">{errors.decisionFactors}</p>
                    )}
                  </div>
                  {formData?.decisionFactors?.includes("Other") && (
                    <div className="form-group">
                      <input
                        type="text"
                        name="decisionFactorsother"
                        placeholder="Please specify"
                        className="form-control"
                        value={formData.decisionFactorsother}
                        onChange={handleChange}
                      />
                      {errors.decisionFactorsother && (
                        <p className="error-msg">
                          {errors.decisionFactorsother}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="form-group custom-radio-btn">
                    <h5>Portfolio Strategy</h5>
                    <p>
                      How does investing in startups fit into your overall
                      portfolio strategy?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Main focus
                          <input
                            type="radio"
                            name="portfolioStrategy"
                            value="Main focus"
                            checked={
                              formData.portfolioStrategy == "Main focus"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Diversification
                          <input
                            type="radio"
                            value="Diversification"
                            name="portfolioStrategy"
                            checked={
                              formData.portfolioStrategy == "Diversification"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Speculative part of the portfolio
                          <input
                            type="radio"
                            name="portfolioStrategy"
                            value="Speculative part of the portfolio"
                            checked={
                              formData.portfolioStrategy ==
                              "Speculative part of the portfolio"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          Other
                          <input
                            type="radio"
                            value="Other"
                            name="portfolioStrategy"
                            checked={
                              formData.portfolioStrategy == "Other"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.portfolioStrategy && (
                      <p className="error-msg">{errors.portfolioStrategy}</p>
                    )}
                  </div>

                  {formData.portfolioStrategy == "Other" && (
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Specify other option"
                        className="form-control"
                        name="portfolioStrategyother"
                        value={formData.portfolioStrategyother}
                        onChange={handleChange}
                      />

                      {errors.portfolioStrategyother && (
                        <p className="error-msg">
                          {errors.portfolioStrategyother}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="form-group custom-radio-btn geographicalpreference">
                    <h5>Geographic Preference</h5>
                    <p>
                      Do you have a geographic preference for your investments?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Local
                          <input
                            type="checkbox"
                            name="geographicPreference"
                            value="Local"
                            checked={
                              formData.geographicPreference?.includes("Local")
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          National
                          <input
                            type="checkbox"
                            value="National"
                            name="geographicPreference"
                            checked={
                              formData.geographicPreference?.includes(
                                "National"
                              )
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          International
                          <input
                            type="checkbox"
                            name="geographicPreference"
                            value="International"
                            checked={
                              formData.geographicPreference?.includes(
                                "International"
                              )
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No preference
                          <input
                            type="checkbox"
                            name="geographicPreference"
                            value="No preference"
                            checked={
                              formData.geographicPreference?.includes(
                                "No preference"
                              )
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.geographicPreference && (
                      <p className="error-msg">{errors.geographicPreference}</p>
                    )}
                  </div>
                </form>
                <div className="btn-box flex space-between">
                  <a onClick={(e)=>gotostartupsignup(e)}  className="cta-btn back-btn" href="#">
                    Back
                  </a>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                       <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default InvestorSignupStep1;
