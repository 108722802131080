/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState ,useRef} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import Footer3 from "../../common/Footer3";
import { apiurl } from "../Url";
import { post, get } from "../../services/services";
import  { setItem } from "../../common/reusable";
import Loaderbutton from "../../common/loaderbutton";

function SignupStep7() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);
  const [formData, setFormData] = useState({
    protectedData: "",
    idProof: "",
    addressProof: "",
    pendingLitigation: "",
    pendingLitigationText: "",
    companyVerification: "",
    taxInformation: "",
  });
  const [errors, setErrors] = useState({});
  const Userid = JSON.parse(localStorage.getItem("UserId"));
  const handleChange = e => {
    const { name, value, files } = e.target;
  
    if (files && files.length > 0) {
      const file = files[0];
      const maxSize = 10 * 1024 * 1024;
  
      if (file.size > maxSize) {
        toast.warn("File size should not exceed 10MB", {
          position: "top-center",
        });
        return;
      }
  
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: file,
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  
  const handleSubmit = e => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.idProof) {
      newErrors.idProof = "ID Proof is required.";
    }
    // if (!formData.addressProof) {
    //   newErrors.addressProof = "Address Proof is required.";
    // }
    if (!formData.pendingLitigation) {
      newErrors.pendingLitigation = "Pending Litigation is required.";
    }
    if (
      !formData.pendingLitigationText &&
      formData.pendingLitigation === "Yes"
    ) {
      newErrors.pendingLitigationText = "Pending Litigation Text is required.";
    }
    if (!formData.companyVerification) {
      newErrors.companyVerification = "Company Verification is required.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("id_proof", formData.idProof);
      formdata.append("address_proof", formData.addressProof);
      formdata.append("pending_litigations", formData.pendingLitigation);
      formdata.append(
        "pending_litigations_notes",
        formData.pendingLitigation === "Yes"
          ? formData.pendingLitigationText
          : ""
      );
      formdata.append("company_verification", formData.companyVerification);
      formdata.append(
        "tax_information",
        formData.taxInformation != null ? formData.taxInformation : ""
      );

      post(`${apiurl}startups/legalCompliances/${Userid}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          if (!response) {
          } else {
            if (!response?.data?.status) {
              toast.error(response?.data?.message);
              setLoading(false);
            } else {
              setItem("url", "/about-business1");
              navigate("/about-business1");
            }
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  };
  const getFileName = file => {
    return file && typeof file === "object" ? file.name : file;
  };
  useEffect(() => {
    window.scroll(0, 0);

    get(`${apiurl}startups/${Userid}`)
      .then(response => {
        if (!response) {
        } else {
          const startupData = response?.data?.startups;

          setFormData({
            pendingLitigation: startupData?.pending_litigations,
            pendingLitigationText: startupData?.pending_litigations_notes,
            taxInformation: startupData?.tax_information,
            generalQuestions: startupData?.hear_about_us,
            idProof: startupData?.id_proof,
            // addressProof: startupData?.address_proof,
            companyVerification: startupData?.company_verification,
            generalQuestionsother: startupData?.hear_about_usother,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  
  const [toottip, SetTooltip] = useState(false);
  const [toottipcompany, SetTooltipcompany] = useState(false);
  const openTooltip = () => {
    timerRef.current = setTimeout(() =>SetTooltip(true), 300);
  
  };

  const closeTooltip = () => {
    clearTimeout(timerRef.current);
    SetTooltip(false);
  };

  const openTooltipcompany = () => {
    timerRef.current = setTimeout(() =>SetTooltipcompany(true), 300);
    
  };

  const closeTooltipcompany = () => {
    clearTimeout(timerRef.current);
    SetTooltipcompany(false);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Udharaa || Legal compliance declarations</title>
      </Helmet>
      <section className="signUp-Sec">
        <div className="content-row flex">
          <div className="left-sidebar">
            <div className="logo-box">
              <img src="src/images/site-logo.svg" alt="" />
            </div>
            <div className="step-wrapper">
              <div className="step-box flex completed">
                <div className="num-box">1</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Identity and Business Verification
                  </h4>
                  <p>Verify Your Details</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">2</div>
                <div className="text-box">
                  <h4 className="blurred-text">Additional Questions</h4>
                  <p> Enhance Your Profile</p>
                </div>
              </div>
              <div className="step-box flex completed">
                <div className="num-box">3</div>
                <div className="text-box">
                  <h4 className="blurred-text">
                    Unit Economics and Business Growth
                  </h4>
                  <p>Showcase Your Potential</p>
                </div>
              </div>
              <div className="step-box flex active">
                <div className="num-box">4</div>
                <div className="text-box">
                  <h4>Legal Compliance and Declarations</h4>
                  <p>Ensure Regulatory Compliance</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">5</div>
                <div className="text-box">
                  <h4>About Business</h4>
                  <p>Additional Info For Better Startup Information</p>
                </div>
              </div>
              <div className="step-box flex">
                <div className="num-box">6</div>
                <div className="text-box">
                  <h4>Completed</h4>
                  <p>Complete Your Registration Process</p>
                </div>
              </div>
            </div>
            <div className="bottom-line"></div>
            <div className="bottom-line linetwo"></div>
            <div className="bottom-line linethreee"></div>
            <div className="bottom-line linefourth"></div>
            <div className="bottom-line linefive"></div>
            <div className="bottom-line linesix"></div>
            <div className="bottom-line lineseven"></div>
          </div>
          <div className="right-box">
            <div className="content-box">
              <div className="form-wrapper">
                <h5 className="text-center">
                  Legal Compliance and Declarations
                </h5>
                <div className="security-section">
                  <div className="security-icon">
                    <img src="src/images/security-icon.png" alt="" />
                  </div>
                  <div className="security-text">
                    <p>
                      Your data is protected with industry-standard security
                      measures.
                    </p>
                  </div>
                </div>
                <form action="">
                <div className="security-section lockdocument">
                  <div className="security-icon">
                  <img
                     className="security-img_form"
                      src="src/images/lock-icon.png"
                      alt=""
                    />
                  </div>
                  <div className="security-text">
                    <p>
                    Your Documents are securely stored on our site.
                    </p>
                  </div>
                </div>
                
                  <h5>KYC Documents Upload</h5>
                  <div className="form-group full">
                    <div
                      className="custom-file"
                      style={{ position: "relative" }}
                    >
                      <ul>
                        <li
                          onMouseEnter={() => openTooltip()}
                          onMouseLeave={() => closeTooltip()}
                        >
                          <h6>ID Proof</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="idproof"
                            name="idProof"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label className="custom-file-label" htmlFor="idproof">
                            {getFileName(formData.idProof)
                              ? getFileName(formData.idProof).length > 20
                                ? `${getFileName(formData.idProof).slice(
                                    0,
                                    20
                                  )}...`
                                : getFileName(formData.idProof)
                              : "Upload File"}

                            <span className="required-sign">*</span>
                          </label>
                        </li>
                        {/* <li>
                          <h6>Address Proof</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="addressproof"
                            name="addressProof"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="addressproof"
                          >
                            {getFileName(formData.addressProof)
                              ? getFileName(formData.addressProof).length > 20
                                ? `${getFileName(formData.addressProof).slice(
                                  0,
                                  20
                                )}...`
                                : getFileName(formData.addressProof)
                              : "Upload File"}

                            <span className="required-sign">*</span>
                          </label>
                        </li> */}
                      </ul>
                      {toottip && (
                        <div className="tooltip-container tooltipdocument">
                          <div className="tooltiptext">
                            <div className="tooltip-arrow"></div>
                            Please submit Aadhaar card, Passport or Driving
                            license.
                          </div>
                        </div>
                      )}
                      {errors.idProof && (
                        <p className="error-msg">{errors.idProof}</p>
                      )}
                      {errors.addressProof && (
                        <p className="error-msg">{errors.addressProof}</p>
                      )}
                    </div>
                  </div>
                  <div className="bottomborder"></div>
                  <div className="form-group custom-radio-btn">
                    <p>
                      Any Pending Litigations?
                      <span className="required-sign">*</span>
                    </p>
                    <ul>
                      <li>
                        <label className="flex">
                          Yes
                          <input
                            type="radio"
                            name="pendingLitigation"
                            value="Yes"
                            onChange={handleChange}
                            checked={
                              formData.pendingLitigation == "Yes" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label className="flex">
                          No
                          <input
                            type="radio"
                            name="pendingLitigation"
                            value="No"
                            onChange={handleChange}
                            checked={
                              formData.pendingLitigation == "No" ? true : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                    {errors.pendingLitigation && (
                      <p className="error-msg">{errors.pendingLitigation}</p>
                    )}
                  </div>
                  {formData.pendingLitigation == "Yes" && (
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Pending Litigation"
                        className="form-control"
                        name="pendingLitigationText"
                        value={formData.pendingLitigationText}
                        onChange={handleChange}
                      />
                      {errors.pendingLitigationText && (
                        <p className="error-msg">
                          {errors.pendingLitigationText}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="bottomborder"></div>
                  <div className="form-group full">
                    <div
                      className="custom-file"
                      style={{ position: "relative" }}
                    >
                      <h5>
                        Company Verification{" "}
                        <span className="if-text">(for startups)</span>
                      </h5>
                      <ul>
                        <li
                          onMouseEnter={() => openTooltipcompany()}
                          onMouseLeave={() => closeTooltipcompany()}
                        >
                          <h6>File Upload</h6>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="registration"
                            name="companyVerification"
                            onChange={handleChange}
                            accept=".svg, .png, .jpg, .jpeg, .pdf"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="registration"
                          >
                            {getFileName(formData.companyVerification)
                              ? getFileName(formData.companyVerification)
                                  .length > 20
                                ? `${getFileName(
                                    formData.companyVerification
                                  ).slice(0, 20)}...`
                                : getFileName(formData.companyVerification)
                              : "Upload File"}
                            <span className="required-sign">*</span>
                          </label>
                        </li>
                      </ul>
                      {toottipcompany && (
                        <div className="tooltip-container tooltipdocument">
                          <div className="tooltiptext">
                            <div className="tooltip-arrow"></div>
                            Please submit certificate of Incorporation, Company
                            Pan Card, Company MOA, or GST Registration
                            Certificate.
                          </div>
                        </div>
                      )}
                      {errors.companyVerification && (
                        <p className="error-msg">
                          {errors.companyVerification}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="bottomborder"></div>
                  <div className="form-group">
                    <label htmlFor="">
                      Tax Information <b className="if-text">(If Applicable)</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Tax Identification Number (TIN)"
                      className="form-control"
                      name="taxInformation"
                      value={formData.taxInformation}
                      onChange={handleChange}
                    />
                  </div>
                </form>
                <div className="btn-box flex space-between">
                  <Link
                    to="/unit-economics-business-growth"
                    className="cta-btn back-btn"
                  >
                    Back
                  </Link>
                  <Link onClick={handleSubmit} className="cta-btn blue-btn">
                    {loading ? (
                      <div style={{ marginLeft: "-30%" }}>
                        <Loaderbutton/>{" "}
                      </div>
                    ) : (
                      "Next"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer3 />
    </>
  );
}
export default SignupStep7;
